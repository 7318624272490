*,
*::before,
*::after {
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* word-break: break-word; */
  font-family: "Open Sans", sans-serif !important;
}

html {
  font-family: "Open Sans", sans-serif !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f7f9fa;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: #343a40;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  color: #03354b;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #03354b;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.multi-select .go3425554998 {
  background: white;
  min-width: 200px;
}

.multi-select .go3425554998 div {
  padding-left: 5px;
}

.timer .react-time-picker__wrapper {
  border: 0 !important;
}

.openclosedisabled {
  text-decoration: none !important;
  background: #cecbcb;
  color: white !important;
  padding: 5px 10px !important;
  border-radius: 5px;
}

.MuiDialog-paperWidthSm {
  max-width: inherit !important;
}

.MuiTableCell-root {
  width: 33%;
}

.parkbtn .MuiTouchRipple-root {
  background: #0843cea1;
}

.mainfeedback .headercolor {
  background-color: red;
}

/* .mainfeedback .divGridRow:nth-child(1) {
    background: #7d97a1 !important;
    color: white;
  } 
  .mainfeedback .divGridRow:nth-child(3) {
    background: #03364b;
    color: white;
  }
  .mainfeedback .divGridRow:nth-child(1) .head {
    background: #0076bb;
    color: white;
  } */
.cloudhead:nth-child(even) {
  background: #7d97a1;
  color: white;
}

.cloudhead:nth-child(odd) {
  background: #03364b;
  color: white;
}

.cloudhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 40px;
}

.headgate {
  padding: 7px 0px;
}

legend {
  max-width: 0.01px !important;
}

.tooltipicon {
  font-size: 10px;
  border-radius: 50%;
}

.tooltipicon {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}

.tooltipicon svg {
  margin-right: 0px !important;
  color: #005fb9;
  background: transparent !important;
  box-shadow: none !important;
}

.printbtn {
  outline: 0 !important;
  box-shadow: none !important;
}

.cardbox {
  margin: 10px;
  padding: 11px;
  border-radius: 8px;
  position: relative;
}

.tooltipicon {
  background-color: none !important;
}

.icontop {
  position: absolute !important;
  top: 0;
  right: 10px;
  background: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.deleteicon {
  float: right;
}

.deleteicon svg {
  font-size: 16px;
}

.samebg {
  background: #effafe;
  padding: 8px 20px;
  font-size: 0.9rem;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5467;
}

.form-control:focus {
  box-shadow: 0 !important;
  outline: 0 !important;
}

@media screen and (max-width: 767px) {
  .searchtext {
    text-align: left;
  }

  .startdatecss input {
    font-size: 12px !important;
  }
}

.icondlete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  background-color: #e8eef2;
  height: 100%;
}

.SameClass {
  /* font-family: OpenSans !important; */
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52677a;
}

.summarylabel {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52677a;
  padding-left: 23px;
}

.mobiledatepadd {
  padding: 8px !important;
}

.summarylabel span {
  width: 35%;
  /* font-family: OpenSans; */
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5467;
  margin: 4px 14px 4px 14px;
}

.summarylabel div {
  width: 58% !important;
}

.daysid {
  width: auto;
  /* font-family: OpenSans; */
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5467;
  /* margin: 4px 14px 4px 14px; */
}

.ruletime {
  /* font-family: OpenSans; */
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #52677a;
  position: relative;
}

.summaryicon {
  /* font-family: OpenSans; */
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a333b;
}

.rightlabel {
  font-size: 14px;
}

.Searchbtn {
  width: auto;
  height: 36px;
  margin: 4px 0 9px 20px;
  padding: 9px 20px 10px 18px;
  border-radius: 2px;
  background-color: #005fb9 !important;
  /* font-family: OpenSans; */
  font-size: 14px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff !important;
}

.Searchbtn svg {
  width: 14px !important;
  height: 14px !important;
  margin-right: 2px !important;
}

.MuiDataGrid-columnHeaderTitle {
  line-height: 1 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  background: #e3ebee;
}

.MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-cellCheckbox {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
}

.filtersection {
  background: #e3ebee;
  margin-top: 13px;
}

.addrule {
  /* width: 154px; */
  height: 36px;
  margin: 0 0 13px 134px;
  padding: 4px 29px 5px 23.5px;
  border-radius: 4px;
  background-color: #019444 !important;
  color: white !important;
}

.addrule svg {
  margin-right: 0px !important;
}

.accoclass {
  box-shadow: 0 4px 5px 0 rgba(209, 214, 229, 0.25) !important;
}

.borderleftsummary {
  border-left: solid 1px #c5ccd8;
}

.backcolor {
  background-color: #fafdff;
}

.paddright {
  padding-right: 11px !important;
}

.summarylabel p {
  color: #3f5467;
}

.MuiSelect-selectMenu {
  background-color: white !important;
}

.calenderimg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #333;
}

.tableheading {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f5467;
  width: 100%;
}

.disablebtn {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  color: #fec6c6;
}

.ablebtn {
  color: #34b570;
}

.icontoggle svg {
  font-size: 26px;
}

.bordered-cell .sameh {
  line-height: 1;
  text-align: center;
}

.enable {
  color: green;
}

.disenable {
  color: red;
}

.top-searchbar {
  padding-left: 98px !important;
}

.addrule {
  float: right;
}

.mobileview {
  align-items: center;
}

.updateoverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  background: #effafea6;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: black;
}

@media screen and (max-width: 1100px) {
  .tableheading {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .mobileview {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .mobileview .innermobileview {
    /* flex-direction: column !important; */
    justify-content: left !important;
  }

  .mobileview .innermobileview select {
    font-size: 12px !important;
  }

  .filtersection {
    position: relative;
  }

  .mobileaddrulebtn {
    position: absolute !important;
    left: 53%;
    bottom: 10px;
  }

  .addrule {
    float: left;
  }

  .top-searchbar {
    padding-left: 50px !important;
  }
}

.icondleteone {
  background-color: #e8eef2;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  height: 100%;
}

.sameflex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
}

.sameflex1 {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  height: 100%;
}

.Mui-selected {
  background-color: #3d78bb !important;
  border: 1px solid rgb(218, 212, 212) !important;
  color: white !important;
}

.MuiList-padding {
  padding: 0px 0px 0px 0px !important;
}

.hidebackground .MuiPaper-root {
  background: transparent !important;
}

.hidebackground .accoclass {
  background: white !important;
}

@media print {
  .samebg img {
    display: none;
  }

  .deleteicon img {
    display: none;
  }

  .printpageview {
    flex-direction: column;
  }

  .printpageview .col-md-6 {
    margin: 0px !important;
  }
}

.MuiTypography-body1 {
  text-align: left !important;
}

.timedetails .timesize {
  text-align: center !important;
}

.backgroundbtn {
  background: #e3ebee;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deletebtn {
  background: #ec3f3f !important;
}

.savebtn {
  background: #005fb9 !important;
}

.listtable tr th {
  padding: 10px 0px;
}

.listtable thead {
  background: #e3ebee;
}

.menubtn::after {
  display: none;
}

.samepadd .MuiSelect-select.MuiSelect-select {
  padding: 11px auto !important;
}

.accohead button {
  font-size: 14px;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #004f9a;
  background: none;
  border: 0;
  box-shadow: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

table,
th,
td {
  border: 1px solid #d6dada;
  border-collapse: collapse;
}

.headname {
  color: #005fb9;
}

.commonsize {
  font-size: 13px;
}

.triger {
  color: #005fb9;
  /* font-weight: 600; */
  padding: 15px 0px;
}

.actionicons {
  min-width: 10px !important;
}

.actionicons a {
  color: #52677a !important;
  font-size: 15px;
  padding: 5px 14px;
  text-align: center;
}

.actionicons a:hover {
  color: #005fb9 !important;
  background-color: #e9f4ff !important;
}

.trigeracco .MuiIconButton-root {
  color: #005fb9 !important;
}

.viewdayofweek .MuiIconButton-root {
  color: #005fb9 !important;
}

.trigeracco {
  margin: 0px 5px;
}

.trigeracco.Mui-expanded {
  margin: 5px 5px !important;
}

.confirmation {
  padding-left: 1.5rem !important;
  margin-top: 8px;
  margin-bottom: 9px;
}

.confirmation label {
  text-align: left;
  padding: 2px;
  color: #3f5467;
  font-size: 12px;
}

.confirmation input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

.notificationicon {
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  z-index: 99;
  position: absolute;
  background: white;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.hrbottomline {
  margin: 0px 20px;
}

.notifybellicon {
  font-size: 1.5rem;
}

.courtselect {
  background: white;
  border: 1px solid #c6c5c5;
  width: 17%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.confirmation input[type="checkbox"]:before {
  font-family: "FontAwesome";
  content: "✓";
  font-size: 14px;
  background: #fff;
  display: flex;
  width: 15px;
  height: 15px;
  border: 1px solid #3f5467;
  margin-right: 7px;
  justify-content: center;
  align-items: center;
  color: transparent !important;
}

.confirmation input[type="checkbox"]:checked:before {
  color: #3f5467 !important;
}

.timeinterval {
  padding: 5px 0px;
}

.ruletime:hover .indiviualdeleteone {
  display: block;
}

.indiviualdeleteone {
  position: absolute;
  top: 0px;
  background: #e9eef2;
  padding: 4px 6px;
  display: none;
  left: 0;
  width: 100%;
}

#dropdown-basic {
  background: none !important;
  color: #3f5467;
  border: 0 !important;
  box-shadow: none !important;
}

#dropdown-basic::after {
  display: none;
}

.MuiOutlinedInput-input {
  padding: 11px 14px !important;
}

.confirmation input[type="checkbox"]:hover {
  cursor: pointer;
}

/* table css ====================== */
.custom-table .rdt_TableCol {
  border: 1px solid #dddddd;
  border-bottom: 0;
  border-top: 0;
  border-right: 0;
  padding: 0px;
}

.custom-table .rdt_Table {
  border-collapse: collapse;
  border: 1px solid #dddddd;
  text-align: center;
  border-left: 0;
  border-spacing: 0;
}

.custom-table .rdt_TableCell {
  border: 1px solid #dddddd;
  border-bottom: 0;
  border-top: 0;
  border-right: 0;
  padding: 8px 0px;
}

.selectpagerange {
  width: 20%;
  padding: 6px;
  background: transparent;
  border: 1px solid #007bff;
  border-radius: 4px;
}

.custom-table .rdt_TableCell {
  width: 100%;
  justify-content: center;
}

.icontoggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-table .rdt_TableHeadRow {
  background: #f6fafa;
}

.custom-table .rdt_TableHead {
  background: #f6fafa !important;
}

.custom-table .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgb(255, 255, 255);
}

.custom-table .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background: #f6fbfc;
}

.jsXNje:nth-of-type(n) {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e3f2fd !important;
  border-bottom-color: #ffffff;
}

.eFOrfb:nth-of-type(n) {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e3f2fd !important;
  border-bottom-color: #ffffff;
}

/* .donCzX {
  background: #f6fbfc !important;
}
.hrapS {
  background: rgb(255, 255, 255) !important;
} */
/* table css ====================== */
.ruleaddbtn {
  padding: 3px 14px !important;
  background: #005fb9 !important;
  color: white !important;
}

.ruleaddbtncancel {
  padding: 2px 14px !important;
  background: transparent !important;
  border: 1px solid #005fb9 !important;
  color: #005fb9 !important;
}

.audittable th,
.audittable td {
  vertical-align: middle !important;
}

.mobilefiltersection {
  background: #e3ebee;
  margin-top: 13px;
  margin-bottom: 13px;
  display: none;
}

.rulepageHeader {
  display: block;
}

.filterapplybtn.btn {
  background: #005fb9 !important;
  color: white !important;
  text-transform: uppercase;
}

.filtercancelbtn {
  text-transform: uppercase;
  border: 1px solid #005fb9 !important;
  background: transparent !important;
  color: #005fb9 !important;
}

.ruleheading {
  /* margin-bottom: 10px; */
  font-size: 14px;
  letter-spacing: normal;
  color: #3d3e3f;
  font-weight: 500;
}

.ruleContent {
  /* margin-bottom: 10px; */
  font-size: 13px;
  letter-spacing: normal;
  color: #52677a;
  font-weight: 402;
}

.timecss {
  font-size: 10px !important;
}

.mobileruleview {
  display: none;
}

.desktopruleview {
  display: block;
}

.notifymobileruleview {
  display: none;
}

.notifydesktopruleview {
  display: block;
}

.content-header {
  align-items: center !important;
}

.makeStyles-toolbar-6 {
  width: 100%;
}

.notiheheading {
  font-size: 1rem;
  font-weight: 600;
}

.notifymessageweblink {
  margin-left: 10px;
}

.notifiystyle {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 950px) {
  .mobilefiltersection {
    display: block;
  }

  .MuiAppBar-positionFixed {
    align-items: center;
  }

  .row.bordermobiletablehead .col-12 {
    padding: 10px 8px;
    border-bottom: 1px solid #b1cbd0;
  }

  .bordermobiletablehead {
    border: solid 1px #b1cbd0;
    border-bottom: 0;
    /* padding: 10px 0px; */
    border-radius: 2px;
    background: #fbfbfb;
  }

  .content-header h3 {
    margin-top: 7px !important;
    font-size: 15px !important;
  }

  .Details .table th {
    padding: 0.5rem !important;
    font-size: 12px !important;
  }

  .Details .table td {
    padding: 0.5rem !important;
    font-size: 13px !important;
  }

  .confirmdialogbox {
    z-index: 1200;
  }

  .searchbtn {
    padding: 5px 10px 5px !important;
    margin-right: 0px !important;
  }

  .searchtext {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    align-items: center;
    margin-bottom: 6px;
  }

  .commonsize {
    font-size: 13px;
  }

  .validitydates .commonsize {
    font-size: 12px;
  }

  .mobilemargin {
    margin: 0px !important;
  }

  .table {
    margin-bottom: 0rem !important;
  }

  .mobilemargin.Mui-expanded {
    margin: 0px !important;
  }

  .settingdropdown {
    margin-top: 0px !important;
  }

  .mobilemenubtn {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .mobilecondition:nth-child(even) {
    font-size: 20px !important;
    padding-left: 0px !important;
  }

  .rulepageHeader {
    margin-bottom: 20px;
    display: none;
  }

  .filterapplybtn.btn {
    background: #005fb9 !important;
    color: white !important;
    text-transform: uppercase;
  }

  .filtercancelbtn {
    text-transform: uppercase;
    border: 1px solid #005fb9 !important;
    background: transparent !important;
    color: #005fb9 !important;
  }

  .searchtext {
    font-size: 15px;
  }

  .filterfooter {
    flex-direction: column;
  }

  .filterfooter button {
    width: 100%;
  }

  .filterby {
    flex-direction: column;
  }

  .filtermodal input {
    font-size: 12px !important;
  }

  .filtermodal select {
    font-size: 12px !important;
  }

  .filtermodal .react-datepicker-wrapper {
    width: 100% !important;
  }

  .content {
    padding: 30px 15px !important;
    margin-left: 35px !important;
  }

  .content-header {
    margin-left: 18px !important;
  }

  .ruletablelist .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
    min-width: 19% !important;
  }

  .ruletablelist .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(4) {
    min-width: 19% !important;
  }

  .priorityno {
    display: inline-block;
    background: #d3e5f5;
    padding: 3px 7px;
    color: #005fb9;
    font-size: 14px;
    font-weight: 600;
  }

  .triggering.ruleContent {
    display: inline-block;
    background: #d3e5f5;
    padding: 3px 7px;
    color: #005fb9;
    font-size: 14px;
    font-weight: 600;
  }

  .triggering.ruleContent div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .triggering.ruleContent span {
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .content-header {
    height: 70px !important;
  }

  .summarylabel span {
    width: 70% !important;
  }

  .status-list .list-item {
    padding: 8px 0px !important;
  }

  .row.mobilerulerow {
    align-items: center;
  }

  .vieweditbtn {
    width: auto !important;
    padding: 10px 9px !important;
  }

  .vieweditbtn span {
    font-size: 12px !important;
  }

  .viewprintbtn {
    width: auto !important;
    font-size: 12px !important;
    padding: 10px 10px !important;
  }

  .viewbackbtn {
    width: auto !important;
    font-size: 12px !important;
    padding: 10px 10px !important;
  }

  .viewbackbtn svg {
    margin-right: 0px !important;
  }

  .downblukactionborder {
    border: 1px solid #b1cbd0;
    border-top: 0;
  }

  .samemobilepadd {
    padding: 10px 8px !important;
  }

  .col-4.col-sm-4,
  .col-8.col-sm-8 {
    margin-bottom: 10px;
  }

  .reloadbtn .reload {
    font-size: 11px !important;
  }

  .searchbtn {
    font-size: 11px !important;
  }

  .content {
    padding: 10px 23px 30px !important;
  }

  .mobileruleview {
    padding: 10px 4px 30px 10px !important;
  }

  .notifymobileruleview {
    padding: 10px 4px 30px 10px !important;
  }

  .mobilecontent {
    padding: 18px 6px 30px !important;
  }

  .mobilemultiselect .MuiInputBase-input {
    padding: 10px !important;
  }

  .ruleid {
    padding: 11px 0px 11px 5px !important;
  }

  .sameviewhead {
    font-size: 14px;
  }

  .ruleidacco .accorhead,
  .statuscolor,
  .statuscolor2 {
    font-size: 15px !important;
  }

  .leftinnerbox>div:nth-child(1) {
    font-size: 0.9rem;
  }

  .desktopruleview {
    display: none;
  }

  .mobileruleview {
    display: block;
    margin-top: 15px;
  }

  .notifydesktopruleview {
    display: none;
  }

  .notifymobileruleview {
    display: block;
    margin-top: 15px;
  }

  .allogs.mobileruleview {
    margin-top: 0px;
  }

  .notifymobileruleview {
    display: block;
    margin-top: 15px;
  }

  .allogs.notifymobileruleview {
    margin-top: 0px;
  }

  .trigeracco {
    margin: 0px;
  }

  .mobilerulerow:nth-child(odd) {
    background-color: #f6fbfc;
  }

  .mobilerulerow:nth-child(even) {
    background-color: #fff;
  }

  .mobilecondition:nth-child(odd) {
    padding-right: 0px !important;
  }

  .commonsize {
    font-size: 13px;
  }

  .searchbydatetime .datepickercss {
    align-items: flex-start !important;
  }

  .mobilewidth {
    width: 100% !important;
  }
}

.notifyemailsmsbox {
  width: 87%;
}

.searchtext {
  width: 43%;
}

.filter {
  width: 9%;
}

.searchbydatetime {
  width: 100%;
}

.filterdatecss {
  width: 20%;
}

.filterdatecss .react-datepicker-wrapper {
  width: 100%;
}

.ruleresearch {
  padding: 0px !important;
}

.searchfilterbox {
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 1px solid #c8d1d8;
}

.vieweditbtn {
  /* width: 135px; */
  height: 36px;
  padding: 9px 20px 10px 18px;
  border-radius: 2px;
  background-color: #d0a41e !important;
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff !important;
}

.viewprintbtn {
  width: 125px;
  height: 36px;
  /* margin-left: 10px !important;
  margin-right: 10px !important; */
  padding: 4px 29px 5px 23.5px;
  border-radius: 4px;
  background-color: #005fb9 !important;
  color: white !important;
}

.colorbluetext {
  color: #005fb9 !important;
  font-size: 20px;
  font-weight: normal;
}

.viewbackbtn {
  height: 36px;
  padding: 4px 29px 5px 23.5px;
  border-radius: 4px;
  background-color: #ffffff !important;
  border: 1px solid #005fb9 !important;
  color: #005fb9 !important;
}

.leftinnerboxlog {
  padding: 10px 20px;
}

.logviewpadd {
  padding: 10px 0px;
  background: #f9fdff;
}

.loghead {
  font-size: 1rem;
  color: #3d3e3f;
  font-weight: 550;
}

.firstrowview {
  background-color: #e5f2f8 !important;
}

.ruleid {
  padding: 20px 5px;
  display: flex;
  justify-content: center;
  align-items: unset;
}

.sameviewhead {
  font-weight: 600;
}

.statuscolor {
  /* color: #019444; */
  font-weight: bold;
  font-size: 20px;
}

.statuscolor .enablecolor {
  color: #019444;
}

.disablecolor {
  color: #f19999 !important;
}

.statuscolor2 {
  color: #005fb9;
  font-weight: bold;
  font-size: 20px;
}

.bordersame {
  border-left: 1px solid #d7e8ec;
  border-right: 1px solid #d7e8ec;
}

.leftinnerbox {
  padding: 10px 0px 10px 22px;
}

.sameviewhead {
  font-size: 1rem;
  color: #3d3e3f;
}

.leftviewside {
  background: #f9fdff;
}

.leftviewside .row {
  justify-content: center;
}

.rightsideview {
  background: white;
  padding: 10px 20px;
  border: 1px solid #f2d9d9;
  border-radius: 5px;
}

.logfirstview {
  border: 1px solid #f2d9d9;
  border-radius: 5px;
}

.rulekey,
.rulevalue {
  padding: 5px 0px;
}

.rulevalue {
  color: #616d7e;
  font-size: 1rem;
}

.leftinnerbox>div:nth-child(1) {
  font-size: 1rem;
  font-weight: 530;
  color: #57595b;
}

.datecss>div:nth-child(2) {
  font-size: 1.2rem;
  font-weight: 530;
  color: #4187cb;
}

.rulekey {
  color: #585859;
  font-size: 0.9rem;
}

.copyruleid span {
  color: #005fb9;
  font-size: 1rem;
  margin-left: 5px;
}

.viewcondition td {
  font-size: 12px;
  color: #616d7e;
  padding: 2px 14px !important;
  width: 50%;
}

.viewcondition td div {
  font-weight: 550;
  color: #52677a;
}

.viewcondition {
  background: #f9fdff;
  border-radius: 5px;
  border: 0 !important;
  margin-bottom: 0px !important;
}

.topbackcolor {
  background: white;
  margin: 10px 0px;
  border: 1px solid #f2d9d9;
  border-radius: 5px;
}

.viewcondition td,
.viewcondition th {
  border-color: #ececec !important;
}

.logviewcondition {
  background: #f4fafd;
}

.conditiontable {
  padding: 5px !important;
}

.smsnotify {
  background: #f9fdff;
  padding: 5px 10px;
  height: 100%;
}

.smshead {
  font-weight: 501;
  font-size: 14px;
  color: #51687a;
}

.smsnotify span {
  font-size: 14px;
}

.printpageview .col-lg-3 {
  padding: 5px;
}

.accorhead {
  min-height: 5px !important;
}

.accorhead .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.viewdayofweek .MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  height: 100%;
}

.viewaccordion {
  background-color: #f9fdff !important;
  box-shadow: none !important;
}

.accorhead .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.viewfilter {
  margin-top: 74px;
}

.ruleid .hash {
  width: 40px;
  height: 40px;
  padding: 6px 15px 6px 15px;
  border: solid 1px #98b3bf;
  background-color: #fff;
  border-radius: 50%;
  color: #8ab6c5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-right: 12px;
}

.ruleidacco {
  box-shadow: none !important;
}

.copyruleid .MuiButtonBase-root {
  padding: 0px 12px !important;
}

.copyruleid .MuiAccordionDetails-root {
  padding: 0px !important;
  font-size: 9px;
}

.copy {
  cursor: pointer;
}

.ruleidacco .accorhead {
  color: #005fb9 !important;
  font-size: 20px;
  text-transform: uppercase;
  padding: 0px !important;
  display: inline-flex !important;
}

.ruleidacco .accorhead .MuiAccordionSummary-content {
  margin: 0px !important;
}

.listrule .conditionlist {
  padding: 0px;
}

.listrule .conditionlist .MuiAccordionSummary-content {
  width: auto !important;
  margin: 12px 0 !important;
}

/* .copyruleid div{
  font-siz  : 10px;
} */
.ruleid .togglestatus {
  width: 44px;
  height: 42px;
  border: solid 1px #98b3bf;
  background-color: #fff;
  border-radius: 50%;
  color: #17951f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 12px;
}

.trigeracco .MuiAccordionSummary-content {
  margin: 12px 0px !important;
}

.ruleid .priority {
  width: 44px;
  height: 42px;
  border: solid 1px #98b3bf;
  background-color: #fff;
  border-radius: 50%;
  color: #8ab6c5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 12px;
}

.ruleid .togglestatus svg {
  font-size: 25px;
}

.ruleid .priority svg {
  font-size: 25px;
  color: #8ab6c5;
}

.vieweditbtn span {
  font-size: 1rem;
}

@media print {

  .content-header,
  .filtersection {
    display: none;
  }

  .ruleid {
    justify-content: left;
  }
}

.Toastify .Toastify__toast-container {
  width: 40% !important;
}

.toastsuccess {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
}

.toasticon svg {
  font-size: 30px;
  color: #4ad369;
}

.erroricon svg {
  font-size: 30px;
  color: #fe355a;
}

.toastmessage {
  color: black !important;
}

.toasticon,
.erroricon {
  margin-right: 16px;
}

.Toastify__toast--success {
  background: linear-gradient(to right, #47d761 1%, #cfffda 1%) !important;
}

.Toastify__toast--error {
  background: linear-gradient(to right, #fe355a 1%, #fbe7eb 1%) !important;
}

.toastcontainer .Toastify__progress-bar {
  background-color: #47d761 !important;
}

.errorcontainer .Toastify__progress-bar {
  background-color: #fe355a !important;
}

.Toastify__close-button {
  color: black !important;
}

.rightsideview .col-12.col-md-3.col-lg-3 {
  width: 20% !important;
  max-width: 20% !important;
}

/* .rightsideview .col-12.col-md-3.col-lg-3 {
  border-bottom: 1px solid #d7e8ec;
}
.rightsideview .col-12.col-md-9.col-lg-9 {
  border-bottom: 1px solid #d7e8ec;
} */
.viewaccordion::before {
  border: 0px !important;
  background-color: transparent !important;
}

.sameh {
  padding: 10px 0px;
}

.listrule {
  padding: 10px 5px;
}

.confirmdialogbox .MuiDialog-paperWidthSm {
  width: 50%;
}

.icondletenew {
  padding: 5px 8px;
  background-color: #e8eef2;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifymessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  background: #fafdff;
}

.searchby {
  display: flex;
  justify-content: left;
  align-items: center;
}

/* .searchtext,
.filtersearchtext {
  margin-bottom: 5px;
} */
.searchby {
  margin-bottom: 10px;
}

.filterby {
  display: flex;
  justify-content: left;
  align-items: center;
}

.top-searchbar input,
.top-searchbar select {
  font-size: 13px !important;
}

.timedetails {
  padding: 0px 6px 16px !important;
}

.timesize {
  font-size: 0.7rem !important;
}

.ruletime {
  font-size: 13px !important;
}

.Details .rulemodaldes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.Details .rulemodaldes div {
  flex-basis: 50%;
}

.Details span {
  font-weight: normal;
  flex-basis: 50%;
}

.Details {
  margin-bottom: 20px;
}

.agreebtn {
  background-color: #0077bb !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 0.2rem 1.25rem !important;
}

.cancelbtn {
  border: 1px solid #0077bb !important;
  color: #0077bb !important;
  border-radius: 20px !important;
  padding: 0.2rem 1.25rem !important;
}

.rulemodaldes {
  margin-bottom: 15px;
}

.weekbtnselect {
  text-align: right;
}

.weekdaycss {
  text-transform: uppercase !important;
  padding: 6px 14px !important;
  background: transparent !important;
  color: #005fb9 !important;
  border: 1px solid #005fb9 !important;
  font-size: 13px !important;
}

.editleft {
  float: left;
}

.editright {
  float: right;
}

.btn-color {
  background-color: transparent !important;
  color: #0077bb !important;
  box-shadow: none !important;
  border: 1px solid #07b !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}

.top-filtersearchbar {
  padding-left: 34px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.printpageview {
  padding-right: 5px;
}

.mobilebackcolor {
  padding-right: 10px !important;
}

.accoclass {
  padding: 0px;
}

.mobilepaddright.backcolor {
  padding-right: 10px;
}

.timesearch label {
  margin-bottom: 0px;
  width: 35%;
}

.formdiv label {
  width: 35%;
  height: auto;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
}

.headgreen {
  color: #0fbf0f;
}

.headerror {
  color: #ec3f3f;
}

.removelogborder .react-datepicker-wrapper {
  width: 100% !important;
}

.allbordersame .form-control {
  border-color: #5b6065 !important;
  padding: 5px !important;
}

.removelogborder input {
  border: 0px !important;
}

.spacebet {
  margin: 5px 0px;
}

.timesearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltip {
  z-index: 99999999 !important;
}

.conditiontool {
  right: -10px !important;
}

.allelment .form-control {
  font-size: 13px;
  border: 1px solid #5b6065;
}

.searchcard .card-header {
  padding: 0.75rem 1rem;
}

.searchcard .card-body {
  padding: 1rem;
}

.logSearchbtn {
  width: 137px;
  border-radius: 2px;
  background-color: #005fb9 !important;
  font-size: 14px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff !important;
  box-shadow: none !important;
}

.logheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logcancelbtn {
  width: 137px;
  border-radius: 2px;
  font-size: 14px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: 1px solid #5b6065 !important;
  box-shadow: none !important;
}

.logsearch {
  line-height: 1.5 !important;
}

.logbtnleftright {
  text-align: end;
}

@media screen and (max-width: 767px) {
  .conditiontable {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .logbtnleftright {
    text-align: center;
  }

  .custom-tooltip {
    inset: 0px auto auto 0px !important;
  }

  .conditiontool {
    right: -16px !important;
  }

  .accoclass {
    padding: 10px;
  }

  .backgroundbtn button {
    font-size: 13px;
  }

  .printpageview {
    padding-right: 0px;
  }

  .editrightpadd {
    padding: 10px 8px 30px !important;
  }

  .addruleItems1 .MuiPaper-root:nth-child(1) {
    padding: 12px 2px 10px !important;
  }

  .editleft {
    float: none !important;
    width: 100%;
  }

  .editright {
    float: none !important;
    width: 100%;
  }

  .leftviewside {
    padding-left: 5px;
  }

  .smshead {
    font-size: 12px;
  }

  .reloadbtn {
    justify-content: space-between !important;
    flex-direction: column;
  }

  .breadcrumb-item>a {
    font-size: 0.8rem !important;
  }

  .btn-color {
    font-size: 11px !important;
  }

  .mobileheader {
    height: 80px !important;
    /* flex-direction: column !important; */
  }

  .ruleidsize {
    font-size: 0.7rem !important;
    overflow-wrap: normal;
    word-break: break-all;
  }

  .breadcrumb {
    justify-content: end !important;
    width: 100%;
  }

  .backlistbtn {
    padding-right: 10px !important;
  }

  .paginationright {
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
  }

  .totalrule {
    margin-right: 0px !important;
    margin-bottom: 5px;
  }

  .rowperpage {
    justify-content: center !important;
  }

  .logfirstview {
    margin-top: 10px;
  }

  #button-tooltip {
    width: 75% !important;
    top: 40% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    opacity: 1 !important;
    z-index: 9999999 !important;
  }

  .filterapplybtn.btn {
    background: #005fb9 !important;
    color: white !important;
    text-transform: uppercase;
  }

  .filtercancelbtn {
    text-transform: uppercase;
    border: 1px solid #005fb9 !important;
    background: transparent !important;
    color: #005fb9 !important;
  }

  .searchtext {
    font-size: 15px;
  }

  .filterfooter {
    flex-direction: column;
  }

  .filterfooter button {
    width: 100%;
  }

  .filterby {
    flex-direction: column;
  }

  .filtermodal input {
    font-size: 12px !important;
    border: 1px solid #747b82 !important;
  }

  .filtermodal select {
    font-size: 12px !important;
    border: 1px solid #747b82 !important;
  }

  .Toastify .Toastify__toast-container {
    width: 88% !important;
    left: 50%;
    top: 6px !important;
    transform: translateX(-50%) !important;
  }

  .confirmdialogbox .MuiDialog-paperWidthSm {
    width: 100%;
  }

  .MuiDialog-scrollPaper {
    height: auto !important;
  }

  .confirmdialogbox .MuiDialog-paper {
    margin: 67px 2px 28px 43px;
  }

  .confirmdialogbox .MuiDialogContent-root {
    padding: 8px 8px;
    max-height: 80vh;
  }

  .rightsideview {
    padding: 10px 10px;
  }

  .ruleid {
    justify-content: left;
  }

  .leftinnerbox {
    padding: unset !important;
    margin-bottom: 10px !important;
  }

  .datecss>div:nth-child(2) {
    font-size: 15px;
  }

  .selectpagerange {
    width: 25%;
  }

  .reloadbtn .reload {
    margin-right: 0px !important;
  }

  .rightsideview .col-12.col-md-3.col-lg-3 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .rightsideview .col-12.col-md-9.col-lg-9 .rulevalue {
    border-bottom: 1px solid #eeecec;
  }

  .rightsideview .col-12.col-md-9.col-lg-9 {
    margin-bottom: 15px;
  }

  .rulekey,
  .rulevalue {
    padding: 0px !important;
  }

  .rulekey {
    padding-bottom: 3px !important;
  }

  .viewcondition td {
    padding: 2px 5px !important;
  }

  .ruletime {
    font-size: 11px !important;
  }

  .mobilegridalign {
    align-items: center;
  }

  .MuiGrid-spacing-xs-2 {
    margin: 0px !important;
  }

  .mobilepadzero {
    padding: 0px !important;
  }

  .btnpaddmobile {
    padding: 2px !important;
  }

  .mobilepaddright {
    padding-right: 3px !important;
  }

  .mobilebackcolor {
    padding-right: 0px !important;
  }

  .accoclass input,
  .accoclass textarea {
    font-size: 12px !important;
    padding: 5px !important;
  }

  .confirmation input {
    padding: 0px !important;
  }

  .rulekey {
    font-size: 13px !important;
    font-weight: 501;
  }

  .MuiAccordionSummary-content {
    margin: 15px 5px !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px !important;
  }

  .cardbox {
    padding: 11px 16px 11px 0px !important;
  }

  .tooltipicon {
    top: -11px !important;
    z-index: 99999999;
  }

  .SameClass {
    font-size: 0.8rem !important;
  }

  .ruleaddbtn {
    font-size: 14px !important;
  }

  .borderleftsummary {
    border: 0 !important;
  }

  .weekbtnselect {
    text-align: center;
  }

  .weekdaycss {
    font-size: 10px !important;
  }

  .MuiAccordionSummary-root {
    padding: 0px 5px !important;
  }

  .rulevalue {
    font-size: 13px !important;
  }

  .smsnotify span {
    font-size: 13px;
  }

  .smsnotify {
    padding: 5px;
  }
}

.ruleidsize {
  font-size: 0.9rem;
  color: #0077bb;
}

.rowperpage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageleft {
  justify-content: left !important;
}

.logtableicons svg {
  font-size: 1.7rem;
}

.updatelogicon {
  color: #005fb9;
}

.diablelogicon {
  transform: rotate(180deg);
  color: #a3a5a6;
}

.enablelogicon {
  color: #019444;
}

.createlogicon {
  color: #fba100;
}

.deletelogicon {
  color: #f76363;
}

.activetext a {
  color: white !important;
}

.backtolist {
  margin-left: 55px;
  padding: 10px 30px;
}

.backlistbtn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.breadcrumb {
  justify-content: center;
  line-height: 30px;
}

.activecolor a {
  color: #005fb9 !important;
}

.backicon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.paginationright {
  display: flex;
  align-items: baseline;
  justify-content: end;
}

.totalrule {
  font-size: 14px;
}

.ruledeletedrule.btn {
  background: #ec3f3f !important;
  color: white !important;
  padding: 5px 20px;
  box-shadow: none !important;
  outline: 0;
  font-size: 14px !important;
}

.togglebtn.btn {
  background: #005fb9 !important;
  color: white !important;
  padding: 5px 10px;
  margin-right: 10px;
  box-shadow: none !important;
  outline: 0;
  font-size: 14px !important;
}

.settingdropdown {
  padding: 0px 5px !important;
  border: 1px solid #bababa !important;
  color: #bababa !important;
  background: #f6fbfc !important;
  margin-top: 15px;
  box-shadow: none !important;
}

.showtablehead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  border: 1px solid #ddd;
  border-bottom: 0;
  padding: 5px 10px;
  background: #fbfbfb;
}

.leftheading {
  font-size: 16px;
  font-weight: 600;
  color: #545657;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relatednewbtn {
  font-size: 11px !important;
}

.datepickercss input {
  margin-right: 5px;
}

.ruletablelist .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  min-width: 20% !important;
}

/* .allogs .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  min-width: 8% !important;
} */

.ruletablelist .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(2) {
  min-width: 20% !important;
}

/* .allogs .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(2) {
  min-width: 8% !important;
} */

.actionlogstatus {
  /* background: #73a6ca; */
  /* color: white; */
  text-transform: uppercase;
  padding: 0px 5px;
  float: right;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
}

.allstatus {
  /* background: #21a6ee; */
  /* color: white; */
  text-transform: uppercase;
  padding: 0px 5px;
  float: right;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
}

.allstatus.truecolor {
  background-color: #e5f8ee;
  color: #0b9444;
}

.allstatus.falsecolor {
  background-color: #fec6c6;
  color: rgb(250, 13, 13);
}

.actionlogstatus.ignoreColor {
  background-color: #e6e6e6;
  color: #717171;
}

.allogs .trigeracco .MuiIconButton-root {
  color: black !important;
}

.rightbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightbtn button {
  margin-left: 10px;
}

.btn-logbtn {
  background: #005fb9 !important;
  text-transform: uppercase;
  color: white !important;
  border-radius: 5px !important;
}

.rightheading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weekdaycss img {
  margin-right: 4px;
}

.activeweekday {
  background: #005fb9 !important;
  color: #ffffff !important;
}

.tooltipicon {
  position: absolute !important;
  top: 0;
  right: 0;
}

/* .tooltip{
  background: #ced4da !important;
} */
.custom-tooltip {
  background-color: #e3ebee !important;
  /* color: black !important; */
  width: 35%;
}

.custom-tooltip .tooltip-inner {
  background-color: #e3ebee !important;
  width: 100% !important;
  max-width: 100%;
  text-align: left;
}

.tooltipcontent {
  color: black;
}

.createrule {
  font-size: 14px !important;
}

.filtermodal {
  top: 57px !important;
  left: 35px !important;
  z-index: 9999 !important;
  width: 93% !important;
}

.filtericon {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}

.top-filtersearchbar span {
  font-size: 14px !important;
}

.reloadbtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reloadbtn .reload {
  display: flex;
  justify-content: center;
  background-color: #d4f7e3;
  color: #4f6a6e !important;
  font-size: 12px;
  padding: 5px 10px;
  margin-right: 15px;
  align-items: center;
  box-shadow: none !important;
}

.searchbtn {
  background-color: transparent !important;
  color: #b32d2e !important;
  font-size: 14px !important;
  padding: 7px 10px 5px;
  margin-right: 15px;
  border: 0;
  box-shadow: none !important;
  border-radius: 3px;
  margin-left: 5px;
}

.hidebtn {
  visibility: hidden;
}

.reloadbtn .reload span {
  margin-left: 6px;
}

.searchbtn span {
  margin-left: 6px;
}

.crossicon {
  font-size: 16px;
  margin-bottom: 3px;
  background: white;
  border-radius: 50%;
}

.rightclearbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.rightsideserach {
  padding: 7px 10px;
  border-left: 1px solid #c8d1d8;
}

.selectedsearch .filteradd {
  border: 1px solid #406984;
  padding: 3px 8px;
  border-radius: 3px;
  color: #406984;
  background: #edf8ff;
  font-weight: 600;
  cursor: pointer;
}

.selectedsearch .filteradd1 {
  border: 1px solid #406984;
  padding: 3px 8px;
  border-radius: 3px;
  color: #406984;
  background: #e3e9e9;
  font-weight: 600;
}

.crossfilter {
  display: none;
}

.filteradd:hover .crossfilter {
  position: absolute;
  background: #f91d1d87;
  color: white;
  padding: 2px;
  top: 0;
  left: 0%;
  /* transform: translate(-50%, 0%); */
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  text-align: center;
  display: block;
}

.searchbtn span {
  text-decoration: underline;
}

.selectedsearch .filteradd {
  display: inline-block;
  margin: 1px;
}

.filteradd {
  position: relative;
}

.editrightpadd {
  padding-left: 15px !important;
}

.paddleft {
  padding-left: 61px;
}

/* demo event css ======================= */
.demoevent {
  flex-grow: 1;
  margin-top: 70px;
  min-height: calc(100vh - 110px);
}

.eventHeadname {
  color: #447697;
  font-size: 1.6rem;
}

.democard>.card {
  margin: 6px !important;
  border: 1px solid black !important;
  padding: 0px 20px;
}

.eventheading {
  padding: 10px 0px;
  font-size: 1.2rem;
  font-weight: 600;
}

.leftwaittime {
  font-size: 13px;
  font-weight: 501;
}

.rightwaittime {
  font-size: 13px;
  color: green;
  font-weight: 600;
}

.rightwaittime svg {
  font-size: 1.5rem;
  margin-left: 10px;
  font-weight: 623;
}

.waittime {
  align-items: center;
  padding: 15px 0px;
}

.lefttotal {
  font-size: 13px;
  font-weight: 501;
  margin-bottom: 10px;
}

.righttotal {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}

.mobileeventheading {
  display: none;
}

.eventheading {
  display: block;
}

.Eventheader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: white;
  box-shadow: 0px 3px 6px #d5cece;
}

.eventcontent {
  padding: 20px 30px 5px 30px !important;
}

.desktopview {
  display: block;
}

.mobileviewevent {
  display: none;
}

.calenderimg:hover {
  cursor: pointer;
}

.form-control {
  box-shadow: none !important;
}

.blukrule {
  height: 34px;
  padding: 0px 20px !important;
  border: 1px solid #019444 !important;
  background-color: #d3f7e3 !important;
  margin-left: 10px !important;
}

.Details table th {
  vertical-align: top !important;
}

.rulebulkaction {
  color: #019444 !important;
  font-size: 0.9rem !important;
  font-weight: 501;
  margin-bottom: 3px;
}

.leftheading .form-control {
  border: 1px solid #5b6065 !important;
  font-size: 13px;
  border-radius: 3px !important;
}

.filtersection .form-control {
  border: 1px solid #5b6065 !important;
  border-radius: 3px !important;
}

.bottomline {
  border: 1px solid #ddd;
  border-top: 0px;
  padding: 5px 10px !important;
}

.userprofilecontainer .Mui-required {
  background: white;
  padding: 0px 2px;
}

.mobilesummarypadd {
  padding-left: 1rem;
  padding-right: 1rem;
}

.destopviewnotify {
  padding: 15px 0px;
}

.form-body.updateusercss label {
  background: white;
  padding: 0px 2px;
}

.rightcenter {
  text-align: right;
}

.mobilecheckrule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
  flex-direction: column;
}

.mobilecheckrule span {
  font-size: 14px;
}

.mobilerulerow {
  padding: 5px !important;
  margin-bottom: 0px;
  border-radius: 2px;
  border: solid 1px #b1cbd0;
}

.pagination .page-item {
  cursor: pointer;
}

.usercontent {
  display: flex;
  justify-content: left;
  align-items: center;
}

.usercontent img {
  width: 50px;
}

.logalerticons svg {
  font-size: 2rem;
}

.allogs table td {
  vertical-align: middle;
  width: 60%;
}

.allogs table {
  background: white;
}

.width40 {
  width: 40%;
}

.allogs .headname {
  color: rgba(0, 0, 0, 0.87);
}

.allogs .viewaccordion {
  margin-bottom: 2px;
}

.norecord {
  border: solid 1px #b1cbd0;
  background-color: #f6fbfc;
  padding: 10px;
  text-align: center;
}

.btn.logviewbtn .actionbutton {
  text-transform: uppercase;
  color: rgba(255, 255, 255);
}

.btn.logviewbtn {
  border-radius: 3px;
  font-size: 12px !important;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  box-shadow: none !important;
  background-color: #005fb9;
}

.logviewbtn {
  box-shadow: none !important;
}

/* .logbordercolor{
  border-color: #009444 !important;
} */
.timesearch .form-control:disabled {
  border: none !important;
}

.RuleIDcss {
  color: #343a40;
  font-weight: 600;
  background: #f7f7f7;
  border-color: #f7f7f7 !important;
}

.RuleIDcss span {
  color: #005fb9;
  font-weight: 600;
}

.logignore {
  border: 1px solid grey !important;
  background-color: #f0f0f0 !important;
}

.logsuccess {
  border: 1px solid #2ce761 !important;
  background-color: #dbf8e3 !important;
}

.lognoaction {
  border: 1px solid #8250df !important;
  background-color: #e2daf3 !important;
}

.logtrue {
  border: 1px solid #0b9444 !important;
  background-color: #e5f8ee !important;
}

.logfalse {
  border: 1px solid rgb(250, 13, 13) !important;
  background-color: #fec6c6 !important;
}

.allogs .viewaccordion {
  border-radius: 4px !important;
}

.textblue {
  color: #005fb9;
}

.filterby .searchbydatetime {
  width: 100%;
}

.mobilepy {
  padding: 0px;
}

.allmobileruleview {
  display: none;
}

.alldesktopruleview {
  display: block;
}

.notificationpark {
  font-size: 0.8rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  background-color: #0076bb !important;
  color: #fff !important;
  margin: 10px 5px !important;
}

.seenotificationpark {
  font-size: 0.8rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  border: 1px solid #0076bb !important;
  color: #0076bb !important;
  margin: 10px 5px !important;
  padding: 4px 8px !important;
}

.logoveruled {
  border: 1px dashed #6a6868 !important;
  background-color: #ece9e9 !important;
}

.logblocked {
  border: 1px dashed #ff1c1c !important;
  background-color: #ffe6e6 !important;
}

.lognotsupport {
  border: 1px dashed #ec8428 !important;
  background-color: #fff5e9 !important;
}

.logtriggered {
  border: 1px dashed #226c0e !important;
  background-color: #f3ffeb !important;
}

.notificationtitle {
  font-size: 1rem !important;
}

.notificationheader {
  align-items: center !important;
}

.modalzidnex {
  z-index: 9999999999 !important;
}

.datepickerwidth .react-datepicker-wrapper {
  display: block !important;
}

.datepickerwidth .notificationlabel {
  font-size: 15px;
}

.datepickerwidth .form-control {
  font-size: 13px !important;
}

.datepickerwidth .col-12 {
  margin-bottom: 10px;
}

.notificlear {
  padding: 5px 15px !important;
}

.searchlogging {
  margin-bottom: 20px;
}

.notificationpark svg {
  font-size: 1.5rem;
  margin-right: 4px;
}

.seenotificationpark svg {
  font-size: 1.5rem;
  margin-right: 4px;
}

.notifydatepicker .react-datepicker-wrapper {
  width: 100%;
}

.notifyheaderbtn {
  justify-content: end !important;
}

.weblinkcss {
  color: #0076bb !important;
}

.checknotifynumber .checkcolor label {
  color: rgb(32, 30, 30);
}

.checknotifynumber .uncheckcolor label {
  color: gray;
}

/* .checkunsubscribe {
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */
.checkunsubscribe button {
  text-decoration: underline !important;
  cursor: pointer;
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
  color: blue !important;
}

.timezone-middle {
  background: white;
  padding: 20px 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 2px #b5aeae45;
}

.timezonebtn {
  background: #0076bb !important;
  color: white !important;
}

.timezonebtn .MuiButton-label {
  font-size: 1rem !important;
}

/* new rulelist css  */

.topbtn button {
  flex-basis: 50%;
  height: 33px;
  padding: 0px;
}

.custommenu .MuiTypography-body1 {
  text-align: center !important;
}

.newcustom-table .rdt_TableCol {
  background: #d8eff3;
  justify-content: left;
  padding: 5px;
  font-weight: 550;
}

.newcustom-table .rdt_Table {
  min-height: 20px !important;
}

.newcustom-table .rdt_TableCell {
  padding: 0px;
}

.newcustom-table .rdt_TableCell {
  padding-left: 5px;
  justify-content: left;
}

.newcustom-table .rdt_TableCell div:first-child {
  white-space: inherit !important;
  overflow: inherit !important;
}

.newcustom-table .rdt_TableHeadRow {
  min-height: 20px !important;
}

.newcustom-table .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  min-height: 20px !important;
  height: 23px;
}

.newcustom-table .rdt_TableBody .rdt_TableRow:nth-child(even) {
  min-height: 20px !important;
  height: 23px;
}

.customdropup .dropdown-toggle::after {
  display: none !important;
}

.settingdropdowntwo {
  padding: 2px 10px !important;
  color: #403f3f !important;
  box-shadow: none !important;
}

.contentrule .topbtn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.contentrule .leftheading {
  justify-content: left;
  align-items: center;
}

.contentrule .showtablehead {
  border: 0px;
}

.searchsdffilter {
  display: flex;
  justify-content: end;
  align-items: center;
}

.contentrule {
  padding: 15px 30px;
}

.contentrule .blukrule {
  height: 32px;
}

.contentrule .leftheading .form-control {
  height: auto;
  padding: 0.3rem 0.75rem;
}

.searchsdffilter .form-control {
  border: 1px solid #5b6065 !important;
  border-radius: 3px !important;
  height: auto;
  padding: 0.3rem 0.75rem;
  font-size: 13px;
}

.contentrule .rowperpage {
  justify-content: left;
}

/* new rulelist css  */

@media screen and (min-width: 951px) {
  .daytimewidth {
    width: 80%;
  }

  .timezonebtn {
    padding: 10px !important;
  }

  .timezonebtn .MuiButton-label {
    font-size: 0.8rem !important;
  }

}

/* @media screen and (min-width: 768px) and (max-width: 1024px) {
  .newcustom-table .rdt_TableRow div:first-child {
    white-space: nowrap !important;
    max-width: 200px;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
} */

@media screen and (min-width: 1200px) {
  .rightsearhfilter {
    width: 80%;
    margin-left: auto;
  }
}

@media screen and (min-width: 951px) and (max-width: 1150px) {
  .box .box-header a {
    word-break: break-all;
    word-wrap: normal;
    font-size: 11px !important;
  }

  .wrapopenclose {
    word-break: normal;
    overflow-wrap: normal;
  }
}

@media screen and (min-width: 951px) and (max-width: 1280px) {
  .searchbtn {
    padding: 0px;
    margin-right: 0px;
  }

  .rowperpage {
    justify-content: end;
    margin-bottom: 6px;
  }

  .contentrule .rowperpage {
    justify-content: unset;
  }

  .pageleft {
    justify-content: left;
    margin-bottom: 0px;
  }

  .content-header {
    margin-left: 44px !important;
    padding: 0 10px !important;
  }

  .icondleteone {
    width: 20%;
    margin: auto;
    justify-content: center;
  }

  /* .summarylabel span {
    width: 55%;
  } */

  .content {
    padding: 30px 10px 30px 0px !important;
  }

  .top-searchbar {
    padding-left: 65px !important;
  }

  .searchfilterbox {
    padding-right: 5px !important;
  }

  .Searchbtn {
    padding: 0px 10px !important;
  }

  .listmenusidebar {
    padding-left: 5px !important;
  }

  .desktopruleview {
    padding: 30px 20px 30px 17px !important;
  }

  .mediumlogview {
    padding: 0px !important;
  }

  .rulepagepadd {
    padding: 30px 20px 30px 0px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1150px) {
  .notifymodal .modal-dialog {
    max-width: 85%;
  }
}

@media screen and (min-width: 767px) and (max-width: 950px) {

  .rowperpage,
  .paginationright {
    justify-content: center;
    margin: 5px 0px;
  }

  .paddleft {
    padding-left: 36px !important;
    padding-bottom: 10px;
  }

  .top-filtersearchbar {
    padding-left: 36px !important;
  }

  .btn.logviewbtn {
    font-size: 11px !important;
    padding: 8px;
  }
}

@media screen and (max-width: 950px) {
  .eventheading {
    font-size: 0.8rem;
  }

  .notifydatepicker .react-datepicker__day-name,
  .notifydatepicker .react-datepicker__day,
  .notifydatepicker .react-datepicker__time-name {
    margin: 0px !important;
  }

  .alldesktopruleview {
    display: none;
  }

  .allmobileruleview {
    display: block;
  }

  .rightlabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobilewidthviewrule {
    padding: 0px 0px 0px 5px !important;
  }

  .status-list .list-item.open label {
    padding: 3px 5px !important;
  }

  .MuiGrid-spacing-xs-2>.MuiGrid-item {
    padding: 8px 2px !important;
  }

  .mobilesummarypadd {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .samebg {
    padding: 8px 10px;
  }

  .checkmargin {
    margin: 2px;
  }

  .rulealways {
    display: inline-block;
    background: #e1f3e9;
    padding: 3px 8px;
    font-weight: 600;
    color: #019444;
  }

  .checkboxbackgound {
    background: #e1edf7 !important;
    border: solid 2px #005fb9;
  }

  .summarylabel {
    padding-left: 0px;
  }

  .paddright {
    padding-right: 15px !important;
  }

  .mobilepaddright {
    padding-right: 3px !important;
  }

  .printpageview {
    padding-left: 0px !important;
  }

  .mobilebackcolor {
    padding-left: 26px !important;
    padding-right: 2px !important;
  }

  .sameflex1,
  .notifymessage {
    padding-right: 2px;
  }
}

@media screen and (max-width: 767px) {
  .eventheading {
    display: none;
  }

  .newdesktoplistview {
    display: none;
  }

  .Mobilefilterbtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bordersame {
    border: 0px !important;
    border-top: 1px solid #d7e8ec !important;
    border-bottom: 1px solid #d7e8ec !important;
  }

  .hrbottomline {
    margin: 0px;
  }

  /* 
  .viewfilter {
    margin-top: 5px;
  } */

  .searchlogging {
    margin-bottom: 10px;
  }

  .rightbtn.logrightbtn button {
    margin-left: 0px;
  }

  .mobilemt {
    padding-top: 0px !important;
  }

  .loghead.leftinnerboxlog {
    padding-bottom: 0px !important;
  }

  .colorbluetext {
    font-size: 16px;
  }

  .logcreatebtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logrightbtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .commonsize.logtableicons img {
    width: 22px;
  }

  .logmobilepadd {
    padding: 0px 5px 10px 10px !important;
  }

  .logheading {
    justify-content: center;
  }

  /* .generallog {
    justify-content: space-between;
  } */

  .ruleheading {
    font-size: 12px;
  }

  .logtableicons svg {
    font-size: 1.4rem;
  }

  .allogs.mobileruleview .ruleheading {
    margin-bottom: 10px;
  }

  .allogs.notifymobileruleview .ruleheading {
    margin-bottom: 10px;
  }

  .logbordercolor .addrule {
    padding: 5px 14px !important;
  }

  .mobilerulerow {
    padding: 10px 5px 0px !important;
  }

  .allogs.mobileruleview .ruleContent {
    margin-bottom: 10px;
  }

  .allogs.notifymobileruleview .ruleContent {
    margin-bottom: 10px;
  }

  .rightbtn {
    margin-top: 10px;
  }

  /* .logrightbtn {
    margin-top: 0px;
  } */

  .triger {
    padding: 0px;
  }

  .actionlogstatus {
    font-size: 10px;
  }

  .formdiv label {
    width: 35%;
  }

  .title1 {
    margin-bottom: 0px !important;
  }

  .rightcenter {
    text-align: center;
  }

  .showtablehead {
    padding: 0px;
    border: 0px;
  }

  .logbordercolor {
    justify-content: center;
  }

  .allogs table td {
    font-size: 11px;
    width: auto;
  }

  .allogs .headname {
    font-size: 12px;
  }

  .allogs .allstatus {
    font-size: 12px;
    float: none;
  }

  .allogs .actionlogstatus {
    font-size: 12px;
    float: none;
  }

  .allogs .trigeracco.Mui-expanded {
    margin: 0px !important;
  }

  .searchcard .card-body {
    padding: 0.5rem;
  }

  .searchcard .card-header {
    padding: 0.75rem 0.5rem;
  }

  .MuiCardActions-root {
    padding: 8px 5px !important;
    left: 0px !important;
  }

  .createrule {
    font-size: 12px !important;
  }

  .paddleft {
    padding-left: 35px;
    margin-bottom: 15px;
  }

  .mobilelineheight {
    line-height: 1 !important;
  }

  .mobilecheckrule span {
    font-size: 15px;
  }

  .addrule {
    height: auto;
    padding: 8px 10px !important;
    background-color: #019444 !important;
    color: white !important;
  }

  .desktopview {
    display: none;
  }

  .mobileviewevent {
    display: block;
  }

  .eventHeadname {
    font-size: 1rem;
  }

  .mobileeventheading {
    display: block;
  }

  .democard>.card {
    margin: 0px !important;
    margin-bottom: 15px !important;
    padding: 10px 10px;
  }

  .mobileeventheading {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .mobilebackcolor {
    padding-left: 14px !important;
  }

  .mobiletablepagination {
    margin-top: 10px;
  }
}

@media screen and (max-width: 580px) {

  .timesearch,
  .formdiv {
    display: flex;
    justify-content: center;
    align-items: first baseline;
    flex-direction: column;
  }

  .formdiv label {
    width: 100%;
  }

  .timesearch label {
    width: 100%;
  }

  .logcancelbtn,
  .logSearchbtn {
    width: 100px;
  }
}

@media screen and (max-width: 410px) {
  .notifyheaderbtn {
    margin-left: 12px !important;
  }

  .notificationpark {
    font-size: 0.7rem !important;
    margin: 10px 0px !important;
    padding: 5px 6px;
  }

  .seenotificationpark {
    font-size: 0.7rem !important;
    margin: 10px 5px !important;
    padding: 4px 5px !important;
  }

  .notifyheaderbtn {
    justify-content: center !important;
  }
}

@media screen and (max-width: 370px) {
  .notificationpark svg {
    font-size: 1.2rem;
    margin-right: 4px;
  }

  .seenotificationpark svg {
    font-size: 1.2rem;
    margin-right: 4px;
  }
}

.notificationconfirmdialogbox .MuiDialog-paperWidthSm {
  width: 90%;
}

.viewaudittorulebtn {
  background-color: #8029b3 !important;
}

.viewruletoauditbtn {
  background-color: #ce5da9 !important;
}

.viewgeneralbtn {
  background-color: #8f71e8 !important;
}

.serachmt {
  margin-top: 44px;
  padding-bottom: 0px !important;
}

@media screen and (max-width: 767px) {
  .serachmt {
    margin-top: 60px;
    padding-bottom: 0px !important;
    padding: 10px 15px 0px !important;
  }

  .mobilecheckrule div button {
    padding: 5px !important;
  }

  .mobilecheckrule div button:nth-child(2) {
    padding: 8px !important;
  }

  .notificationconfirmdialogbox .MuiDialog-paperWidthSm {
    width: 100%;
    margin: 5px;
  }

  .notificationconfirmdialogbox .MuiDialog-paperWidthSm .MuiDialogContent-root {
    padding: 5px;
  }

  .notificationdetail .table td {
    word-break: break-word;
  }

  .notificationconfirmdialogbox .MuiDialog-container {
    height: 100% !important;
  }
}
.listcontainer {
  height: auto;
}

div.list-item>label.listItemLabelStyle {
  padding: 3px 5px !important;
  margin-bottom: 0.5rem;
  height: auto;
  margin-bottom: 0.5rem;
  font-size: 12px !important;
}

.sameheightcard {
  height: 100%;
  position: relative;
}

.directionbtnposition {
  padding: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

div.list-item>span.listItemSpanStyle {
  /* height: calc(100% - 25px) !important; */
  margin-bottom: 10px;
  display: table !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -8px) scale(0.75) !important;
  background: white;
  padding: 5px 5px;
  font-size: 14px;
}

.listItemSpanDivStyle {
  display: table-cell !important;
  vertical-align: middle;
}



@media only screen and (max-width: 600px) {
  div.list-item>label.listItemLabelStyle {
    padding: 3px 10px !important;
    margin-bottom: 0.5rem;
    height: 19.5px;
    font-size: 9px !important;
  }

  div.list-item>span.listItemSpanStyle {
    /* height: calc(100% - 19.5px) !important; */
    margin-bottom: 10px;
  }
}

/* my css MT-58 issue */

.conheight {
  padding: 16px 16px 40px !important;
}

.dash-footer {
  margin-top: 80px;
}

/* MT-58 issue  */
.fieldgrouppad {
  padding: 0px 8px;
}

.samesize {
  text-transform: uppercase;
}

.w-100.fieldgrouppad .listcontainer {
  margin-bottom: 3px !important;
}

.padleft {
  padding-left: 0px !important;
}

body {
  background-color: #f8f8f8 !important;
}

.jss3 .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4 {
  justify-content: center;
}

.makeStyles-section1-3 .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4 {
  justify-content: center;
}

.status-list {
  margin-bottom: 15px !important;
}

@media only screen and (max-width:767px) {
  .fieldgrouppad {
    padding: 0px !important;
  }

  .MuiGrid-spacing-xs-2 {
    width: 100% !important;
  }

  .conheight {
    padding: 16px 5px 53px 5px !important;
  }

  .status-list {
    margin-bottom: 0px !important;
  }


}

@media only screen and (max-width:700px) {
  .parkimg {
    height: 200px;
  }

}
.listcontainer {
    height: auto;
}

div.list-item>label.listItemLabelStyle {
    padding: 3px 10px !important;
    margin-bottom: 0.5rem;
    height: 25px;
    margin-bottom: 0.5rem;
    font-size: 12px !important;
    overflow-wrap: normal;
    word-wrap: normal;
    word-break: keep-all;
}

div.list-item>span.listItemSpanStyle {
    /* height: calc(100% - 25px) !important; */
    margin-bottom: 10px;
    display: table !important;
    height: 40px;
    line-height: 16px;
}

.listItemSpanDivStyle {
    display: table-cell !important;
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    div.list-item>label.listItemLabelStyle {
        padding: 3px 10px !important;
        margin-bottom: 0.5rem;
        height: 19.5px;
        font-size: 9px !important;
    }

    div.list-item>span.listItemSpanStyle {
        /* height: calc(100% - 19.5px) !important; */
        margin-bottom: 10px;
    }
}

/* my css MT-58 issue */

.conheight {
    padding: 16px 16px 40px !important;
}

.dash-footer {
    margin-top: 80px;
}

/* MT-58 issue  */
.fieldgrouppad {
    padding: 0px 8px;
}

.samesize {
    text-transform: uppercase;
}

.w-100.fieldgrouppad .listcontainer {
    margin-bottom: 3px !important;
}

.padleft {
    padding-left: 0px !important;
}

body {
    background-color: #f8f8f8 !important;
}

.jss3 .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4 {
    justify-content: center;
}

.makeStyles-section1-3 .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4 {
    justify-content: center;
}

.status-list {
    margin-bottom: 15px !important;
}

@media only screen and (max-width:700px) {
    .parkimg {
        height: 200px;
    }
}

.togglebtn {
    cursor: pointer;
    position: relative;
}

.mousepointer:hover {
    cursor: pointer !important;
}

.adminbtnaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
}

.status-list .list-item.Open {
    background-color: #e5f8ee;
    color: #0b9444;
}

.status-list .list-item.Open label {
    font-weight: 500;
    border-radius: 4px !important;
    background-color: #b8f0d2;
    color: #0b9444;
    text-transform: uppercase;
}

.status-list .list-item.Closed {
    background-color: #f6e4e4;
    color: red;
}

.status-list .list-item.Closed label {
    font-weight: 500;
    border-radius: 4px;
    background-color: #fec6c6;
    color: red;
    text-transform: uppercase;
}

.margincfdp {
    margin-bottom: 2px !important;
}

.togglebtn2 {
    position: relative;
}

.status-list .list-item {
    justify-content: space-between !important;
}

/* 
  .gatelineheight{
    line-height: 30px;
  }
  .cfdpgate{
    line-height: 33px;
  } */
/* golf course css =========================== */

.golfcarousel span.visually-hidden {
    display: none;
}

.golfcarousel .list-item {
    position: relative;
}

.golfcarousel .redtoggle {
    position: absolute;
    top: -5px;
    right: 5px;
}

.golfcarousel .greentoggle {
    position: absolute;
    top: -5px;
    right: 5px;
}

.fontweight600 {
    font-weight: 600;
}

.golfcarousel .list-item.closed {
    background-color: #f6e4e4;
    color: red;
}

.golfcarousel .list-item.open {
    background-color: #e5f8ee;
    color: #0b9444;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.golfcarousel .list-item.closed label {
    background-color: #fec6c6;
    border-radius: 4px;
    color: red;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 10px;
    text-transform: uppercase;
}

.golfcarousel .list-item.open label {
    background-color: #b8f0d2;
    border-radius: 4px;
    color: #0b9444;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 10px;
    text-transform: uppercase;
}

.list-item.wetcolor {
    background-color: #ebf0f3;
    color: #1c5980;
}

.list-item.wetcolor label {
    background-color: #c1e1f4;
    color: #155b88;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 10px;
    text-transform: uppercase;
}

.list-item.verywetcolor {

    background-color: #d7e1e8;
    color: #3d79a2;
}

.list-item.verywetcolor label {

    background-color: #b5daf4;
    color: #3d79a2;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 10px;
    text-transform: uppercase;
}

.frontgolfpadd li label {
    padding: 3px 10px !important;
}

/* .golfcarousel .list-item.open .drycolor{
    background-color: #fad48d;
    color: #e99f00;
  } */

.golfcarousel .carousel-control-prev,
.golfcarousel .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.golfcarousel .carousel-control-prev {
    right: 40px !important;
}

.golfcarousel .carousel-control-prev {
    left: auto !important;
}

.golfcarousel .carousel-control-next,
.golfcarousel .carousel-control-prev {
    width: auto !important;
    top: -22px !important;
    bottom: auto !important;
}

.golfcarousel .carousel-control-prev {
    left: 0;
}

.golfcarousel {
    margin: 8px 0px;
}

.golfcarousel .carousel-indicators {
    bottom: -22px;
}

.golfcarousel .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 8px !important;
    height: 8px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0b9444 !important;
    background-clip: padding-box;
    border: 0;
    /* transition: opacity 0.3s ease; */
    opacity: 0.5;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-radius: 50% !important;
}

.golfcarousel .carousel-indicators .active {
    opacity: 1;
}

.golfcarousel .carousel-inner {
    height: 180px;
}

.golfitems {
    padding: 3px !important;
}

.admingolf .golfitems {
    cursor: pointer;
}

.overlaybg::after {
    content: "";
    position: absolute;
    background: #3130302e;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.logoBlockgolf {
    width: 24% !important;
    height: 60px;
    margin-bottom: 10px;
}

.logoBlockgolf img {
    width: auto !important;
    height: 100%;
}

.golftoolbar {
    display: flex;
    padding: 0;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.mobileicon {
    display: none;
}

.desktopicon {
    display: flex;
}

.golfemailpass .MuiInputLabel-shrink {
    transform: translate(0, -5px) scale(0.75) !important;
}

.golfleftheight {
    max-height: 373px !important;
    height: auto !important;
}

.golfcarousel {
    font-size: 1rem !important;
}

.adminbtnaction {
    font-size: 1rem !important;
}

.golfselectbox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
}

.golfselectbox .form-control {
    font-size: 13px !important;
}

.golfnotificationpark {
    font-size: 0.7rem !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    background-color: #0076bb !important;
    color: #fff !important;
    margin: 0px !important;
    padding: 8px !important;
}

.golfupdatebtn {
    box-shadow: none !important;
    padding: 0px !important;
    display: contents !important;
}

.golfactionbtn {
    text-align: center;
}

.btn.golfupdatebtn svg {
    margin: 0px 5px !important;
}

.deletebtncolor svg {
    color: #ff0000;
}

.updatebtncolor svg {
    color: blue;
}

.filterapplybtn.btn {
    margin-right: 1rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .logoBlockgolf {
        width: 28% !important;
    }

    .golfapptitle {
        font-size: 1.2rem !important;
    }
}

@media only screen and (max-width: 950px) {
    .filterapplybtn.btn {
        margin-right: 0rem !important;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .golfcarousel .carousel-inner {
        height: auto !important;
    }

    .mobilegolffooter {
        justify-content: center !important;
        align-items: center !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .mobilegolffooter .filterapplybtn {
        margin: 0 !important;
        margin-bottom: 10px !important;
    }

    .golfMositiureheading h3 {
        font-size: 1.5rem;
    }

    .mobilegolfpadd {
        padding: 0;
    }

    .logoBlockgolf {
        width: 35% !important;
        height: auto;
    }

    .golfpageheader {
        margin-top: 10px !important;
        margin-bottom: 15px !important;
        align-items: center !important;
    }

    .Forecast__title {
        font-size: 0.7rem;
    }

    .Forecast__temperature {
        font-size: 0.8rem;
    }

    .WeatherAndForecast {
        height: auto;
    }

    .mobilewidth {
        width: 100% !important;
    }

    .golfmobilepadd {
        padding: 0 !important;
    }

    .golfcloseallmodal .MuiDialog-paper {
        padding: 10px !important;
        width: 100% !important;
        margin: 10px !important;
        max-height: 97vh !important;
    }

    .golfapptitle {
        font-size: 0.8rem !important;
    }

    .logoBlockgolf img {
        width: 100% !important;
        height: 100%;
    }

    .logoutcss {
        position: absolute !important;
        top: 39px !important;
        right: 0px !important;
        z-index: 99 !important;
    }

    .desktopicon {
        display: none;
    }

    .mobileicon {
        display: flex;
    }

    .logoutcss li {
        box-shadow: 0px 0px 5px gray !important;
        background: white !important;
        padding: 10px !important;
        line-height: 20px !important;
        min-height: 20px !important;
        border-radius: 5px !important;
    }

    .golfmobilepadd .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 8px 8px !important;
    }
}

/* golf course css =========================== */
.Forecast__title {
  font-size: 0.9rem;
  text-align: center;
  color: #fff;
}

.Forecast__weather-icon {
  width: 30px;
  height: 30px;
}

.Forecast__weather-icon:hover {
  animation-name: icons;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes icons {
  0% {
    position: relative;
    top: 0;
  }
  50% {
    position: relative;
    top: 0.25rem;
  }
  100% {
    position: relative;
    top: 0;
  }
}

.Forecast__temperature {
  display: flex;
  justify-content: center;
}

.temperature__max {
  margin-right: 0.2rem;
  color: #f37928;
}

.temperature__min {
  margin-left: 0.2rem;
}

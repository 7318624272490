/* Body Layout */
$primaryColor: "#03354b";

label {
  line-height: 1.5;
}

code {
  font-family: 'Open Sans', sans-serif;
}

p {
  margin: 0 !important;
  color: #4e4a4a;
}

h6,
.h6 {
  font-size: 1.1rem !important;
}

.fieldsetCls {
  border: 1px solid #ddd !important;
  padding: 10px !important;
}

.dbtitle {
  color: #0076bb;
}

.title {
  width: 40vw;
  color: #0076bb;
  text-align: center;
}

.green2 {
  color: #009444;
}

.subtitle {
  text-align: center;
  color: #009444;
}

.gray {
  color: #757575;
}

.search {
  width: 40vw;
}

.legendCls {
  width: 60px !important;
  margin-left: 5px !important;
  font-size: 0.9rem !important;
  padding: 5px !important;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #eaeaea;
}

*::-webkit-scrollbar-thumb {
  background-color: #aca7a7;
  border: 2px solid #aca7a7;
  border-radius: 10px;
}

a {
  color: #03354b !important;
}

a:hover {
  cursor: pointer;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
  outline: 0;
}

html.scroll-smooth {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

main {
  margin-top: 50px;
  min-height: 80vh;
  background: #f7f9fa;
}

.content-header {
  background: #fff;
  padding: 0 30px;
  box-shadow: 0 0 35px 0 rgba(73, 80, 87, 0.15);
  height: 40px;
  align-items: center;
  justify-content: space-between;
  line-height: 70px;
  display: flex;
  margin-left: 55px;
}

.content-header h3 {
  font-size: 18px;
  margin-top: 10px;
}

.content-header> {
  margin: 0;
}

.content-wrapper {
  width: 100%;
  display: block;
}

.contentWrapper {
  flex-grow: 1;
  margin: 1% 10% 1% 10%;
  min-height: calc(100vh - 180px);
}

.breadcrumb {
  background-color: transparent !important;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.breadcrumb-item>a {
  color: #adb5bd !important;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-family: "Material Design Icons";
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item .active {
  color: #03354b !important;
}

.select-box {
  width: 100px;
  height: 35px;
  border: 0;
  background: transparent;
  font-size: 0.775rem;
  font-weight: bold;
  color: $primaryColor;
}

.breadcrumb {
  background-color: transparent !important;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.breadcrumb-item>a {
  color: #495057;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-family: "Material Design Icons";
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item .active {
  color: #adb5bd;
}

#NotifyBlock {
  padding: 10px;
  margin: 0 auto;
  display: block;
}

.select-box {
  width: 100px;
  height: 35px;
  border: 0;
  background: transparent;
  font-size: 0.775rem;
  font-weight: bold;
  color: $primaryColor;
}

.content {
  padding: 30px;
  margin-left: 55px;
}

.content-middle {
  width: 70%;
  margin: 0 auto;
}

.content-sub-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
}

/* Data Grid */
.DataGrid {
  display: table;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.DataGrid .DataGridHeader {
  display: table-header-group;
  font-weight: 500;
  background: #03354b;
  color: #fff;
}

.DataGrid .DataGridBody {
  display: table-row-group;
}

.DataGrid .DataGridRow {
  display: table-row;
  page-break-inside: avoid;
}

.DataGrid .DataGridBody .DataGridRow:nth-child(even) {
  background: #eaeff1;
}

.DataGrid .DataGridRow .DataGridCell {
  display: table-cell;
  padding: 5px 10px;
  border-bottom: none;
  border-right: none;
  vertical-align: middle;
  text-align: left;
  border-top: 1px solid #dddbdb;
  border-left: 1px solid #dddbdb;
}

.DataGrid .DataGridBody .DataGridRow .DataGridCell:last-child {
  border-right: 1px solid #dddbdb;
}

.DataGrid .DataGridBody .DataGridRow:last-child .DataGridCell {
  border-bottom: 1px solid #dddbdb;
}

.plainGrid {
  display: table;
  width: 100%;
  margin: 10px 0;
}

.plainGrid .plainGridHeader {
  display: table-header-group;
  font-weight: 500;
}

.plainGrid .plainGridBody {
  display: table-row-group;
}

.plainGrid .plainGridRow {
  display: table-row;
  page-break-inside: avoid;
}

.plainGrid .plainGridRow .plainGridHead {
  font-weight: bold;
}

.plainGrid .plainGridRow .plainGridHead,
.plainGrid .plainGridRow .plainGridCell {
  display: table-cell;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 0.9rem;
  color: #4e4a4a;
}

/* Widths */
.fc-width100 {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: #495057;
}

.fc-width50 {
  width: 50%;
}

/* Margins*/
.m-0 {
  margin: 0;
}

.marL-5p {
  margin-left: 5%;
}

.mar10 {
  margin: 10px;
}

.marT10 {
  margin-top: 10px;
}

.marT2p {
  margin-top: 2%;
}

.marB0 {
  margin-bottom: 0px;
}

.marB10 {
  margin-bottom: 10px;
}

.marR5p {
  margin-right: 5%;
}

.marR2p {
  margin-right: 2%;
}

/*Padding */
.padB0 {
  padding-bottom: 0px !important;
}

.pad10 {
  padding: 10px;
}

.pad20 {
  padding: 20px;
}

.pad-5 {
  padding: 5px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

/*Form */
.form-row-layout {
  display: flex;
  flex-direction: row;
}

.form-row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-column-layout {
  display: flex;
  flex-direction: column;
}

.contentBox {
  width: 100%;
  background: #f7f9fa;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-bottom: 10px;
}

.contentBoxRow {
  display: flex;
  padding-top: 10px;
  justify-content: space-around;
  align-items: center;
}

.contentBoxRow>div:first-child {
  width: 25%;
}

.control-label {
  padding: 0 10px;
}

.form-label {
  height: 40px;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 40px;
  color: #6f6f6f;
}

.form-footer {
  padding: 10px 20px;
  text-align: right;
  border-top: 1px solid #ddd;
}

// .signArrowIcon{animation: passing 5s infinite;}
// @keyframes passing {
//     0% {
//     -webkit-transform: translateX(-50%);
//     transform: translateX(-50%);
//     opacity: 0;
//     }
//     50% {
//     -webkit-transform: translateX(0%);
//     transform: translateX(0%);
//     opacity: 1;
//     }
//     100% {
//     -webkit-transform: translateX(50%);
//     transform: translateX(50%);
//     opacity: 0;
//     }
//     }
.marL-10p {
  margin-left: 10%;
}

.logoBlock {
  width: 200px;
  height: 60px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.parkMap {
  border: 1px solid #dddbdb;
  border-radius: 2px;
  padding: 2px;
}

.LoginLink {
  text-align: center;
  font-size: 13px;
}

.LoginLink a {
  font-weight: bold;
}

.icons {
  display: flex;
  margin: 0 10px;
}

.error {
  color: red;
}

.icons.primary {
  color: $primaryColor;
}

.btnFacebook {
  background-color: #fff !important;
  color: #4267b2 !important;
  width: 200px;
  height: 50px;
  border: 1px solid #e6e6e6;
  box-shadow: none !important;
  font-size: 1rem;
  font-weight: bold;
}

.btnIcon {
  width: 25px;
  height: 25px;
  color: #03354b;
  background: #fff;
  border-radius: 100%;
}

.fa-facebook-official {
  font-size: 1.5rem !important;
  padding-right: 5px;
}

.btnGoogle {
  border: 1px solid #e6e6e6;
  box-shadow: none !important;
}

.primaryColor {
  color: #03354b;
}

.sucessColor {
  color: #00c853;
  font-weight: initial;
}

.d-flex {
  display: flex;
}

.alignCenter {
  align-items: center;
}

.dF-row {
  flex-direction: row;
}

.marTB10 {
  margin: 10px 0 10px 0;
}

.f-right {
  float: right;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.user-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.user-info h6 {
  margin: 0;
  text-transform: none;
}

.user-info span {
  font-size: 10px;
}

.fb-500 {
  font-weight: 500;
}

.f-12 {
  font-size: 12px;
}

.f-white {
  color: #fff;
}

/* Box-layout*/
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #03354b;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.box.box-primary {
  border-top-color: #03354b;
}

.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}

.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}

.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.collapsed-box .box-header.with-border {
  border-bottom: none;
}

.box-header .box-title {
  display: inline-block;
  // margin: 0;
  line-height: 1;
}

.box-header>.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
  display: flex;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}

.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #fff;
}

.dash-footer {
  background-color: #0076bb;
  color: #fff;
  text-align: center;
  height: 50px;
  line-height: 50px;
  position: relative;
  // bottom:-135px;
  width: 100%;


}

.divGrid {
  display: table;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  border: 1px solid #dddbdb;
  border-bottom: none;
  border-radius: 4px;
}

.divGrid .divGridHeading {
  display: table-header-group;
  font-weight: bold;
  // background: #bdbdbd;
}

.divGrid .divGridBody {
  display: table-row-group;
}

.divGrid .divGridRow {
  display: table-row;
  page-break-inside: avoid;
}

.divGrid .divGridRow .divGridHead {
  border-bottom: 1px solid #dddbdb;
}

.divGrid .divGridBody .divGridRow:first-child .divGridCell {
  border-top: none;
}

.divGrid .divGridRow .divGridCell {
  border-bottom: none;
}

.divGrid .divGridRow .divGridCell,
.divGrid .divGridRow .divGridHead {
  display: table-cell;
  padding: 5px 10px;
  border-right: none;
  vertical-align: middle;
  text-align: left;
  // border-left: 1px solid #dddbdb;
}

.divGrid .divGridRow .divGridCell {
  border-top: 1px solid #dddbdb;
}

.divGrid .divGridRow .divGridHead {
  padding: 10px;
}

.divGrid .divGridRow .divGridHead div {
  border-right: 2px solid #dddbdb;
}

.divGridBody .divGridRow:nth-child(even) {
  background: #fafafa;
}

.divGrid .divGridBody .divGridRow:last-child .divGridCell {
  border-bottom: 1px solid #dddbdb;
}

/* .divGrid .divGridBody .divGridRow:nth-child(odd) {
		background: #effbff;
	}

	.divGrid .divGridBody .divGridRow:nth-child(even) {
		background: #fffcfc;
	} */
.twilightBox {
  display: flex;
  padding: 10px;
  align-items: center;
}

.borderRight {
  border-right: 2px solid #ddd;
}

.twilightBox img {
  width: 50px;
  height: 50px;
}

.twilightBox h3 {
  color: #03354b;
  margin: 0 10px;
}

/*Rules CSS */
.stepper-row {
  margin-bottom: 2%;
}

.stepper-row-head {
  display: flex;
  align-items: center;
}

.stepper-row-content {
  margin-left: 5%;
}

.stepper-footer {
  padding: 10px 0;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
}

.customInputBase {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  width: 90%;
}

.btn-primary {
  color: #fff;
  background-color: #03354b !important;
  border-color: #03354b !important;
}

.btn-primary:hover {
  background-color: #03354b !important;
  border-color: #03354b !important;
}

.daterangepicker .ranges li.active,
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #00c853 !important;
}

.daterangepicker td.in-range {
  background-color: #eefff5;
}

.daterangepicker.opensright:before {
  left: 199px !important;
}

.daterangepicker.opensright:after {
  left: 200px !important;
}

.rules-middle {
  width: 100%;
  margin: 0 auto;
}

.addruleItems {
  margin-top: 50px !important;
  width: 100%;

  p.rulename {
    padding: 10px;
  }

  p.selectrulename {
    font-size: 14px;
    padding-top: 17px;
    text-transform: capitalize;
    text-align: center;
  }

  ul.steps {
    p {
      margin-bottom: 5px !important;
      margin-left: -35px !important;

      i {
        width: 30px;
        height: 30px;
        background: #00000061;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        padding: 5px;
        font-style: normal;
        margin-right: 4px;
        color: #fff;
      }
    }

    li {
      margin-top: 10px;
      margin-left: 45px;
      cursor: default;
    }

    li.stepActive {
      i {
        background: #3fb548;
        color: #fff;
      }
    }
  }
}

.closebtnnotification {
  font-size: 1rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  background-color: transparent !important;
  color: #070707 !important;
  box-shadow: none !important;
  border: 0 !important;
}

.closebtnnotification:hover {
  font-size: 1rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  background-color: transparent !important;
  color: #070707 !important;
  box-shadow: none !important;
  border: 0 !important;
}


.addruleItems1 {
  width: 100%;

  p.rulename {
    padding: 10px;
  }

  p.selectrulename {
    font-size: 14px;
    padding-top: 17px;
    text-transform: capitalize;
    text-align: center;
  }

  ul.steps {
    p {
      margin-bottom: 5px !important;
      margin-left: -35px !important;

      i {
        width: 30px;
        height: 30px;
        background: #00000061;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        padding: 5px;
        font-style: normal;
        margin-right: 4px;
        color: #fff;
      }
    }

    li {
      margin-top: 10px;
      margin-left: 45px;
      cursor: default;
    }

    li.stepActive {
      i {
        background: #3fb548;
        color: #fff;
      }
    }
  }
}

.listItems {
  min-height: 320px;

  ul {
    padding: 0px;

    li {
      list-style: none;
      margin-top: 10px;
      cursor: default;
      margin-left: 10px;

      span {
        background: #e4e4e4;
        padding: 5px 15px;
        border-radius: 50px;
        display: inline-block;
        margin-bottom: 4px;
        margin-right: 6px;
      }
    }
  }
}

.ruledocument {
  border: none;
  background: transparent;
  font-size: 12px;
}

.rule-top-0 {
  margin-top: 0px !important;
}

.addruleItemsClose {
  position: absolute !important;
  right: 12px;
  top: 12px;
}

.notify {
  line-height: normal;
}

@media only screen and (max-width: 600px) {

  .status-list .list-item.open label,
  .status-list .list-item.closed label {
    font-size: 9px !important;
  }

  .notify,
  .dbtitle {
    font-size: x-small;
  }

  .xs-hidden {
    display: none;
  }

  .subtitle {
    font-size: medium;
  }

  .subtitle>small {
    display: block;
  }

  .blue1 {
    color: #0076bb;
  }

  .subtitle1 {
    font-size: 0.8rem !important;
  }

  .logoBlock {
    width: 100px;
    height: 30px;
    padding-left: 2%;
  }

  .locator {
    padding-right: 3%;
  }

  .title {
    font-size: 0.7rem;
  }

  //XS
  .display-xs-0 {
    display: none;
  }

  .borderRightNone {
    border-right: none;
  }

  .xs-floatNone {
    float: none;
  }

  .content-header {
    padding: 10px;
    line-height: normal;
    margin-left: 38px !important;
    align-items: flex-start;
  }

  .content-header h3 {
    font-size: 12px;
  }

  .content-middle {
    width: 100%;
  }

  .content {
    padding: 5px;
    margin-left: 45px;
  }

  p {
    font-size: 0.9rem;
  }

  .breadcrumb-item>a {
    font-size: 0.6rem;
  }

  .content-sub-header h3 {
    font-size: 1rem;
    margin: 0;
    line-height: 35px;
    font-weight: bold;
  }

  .contentBoxRow {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .contentBoxRow>div:first-child {
    width: 80%;
  }

  .twilightBox h3 {
    font-size: 1.3rem;
  }

  .gridScroll {
    overflow: auto;
    width: calc(100%);
  }
}

@media only screen and (max-width: 960px) {
  // .content-middle {
  //   width: 90%;
  // }
}

@media only screen and (max-width: 1280px) {
  .contentWrapper {
    margin: 2%;
  }
}

.Toastify__toast,
.Toastify__toast-body {
  font-size: 14px;
  min-height: 30px;
}

/* Card Block */
.title1 {
  color: #009444 !important;
  font-size: 13px !important;
  font-weight: bold;
}

.updated {
  color: #978E8E;
}

.status-list {
  padding: 0;

  li {
    border: 1px solid #ddd;
    list-style-type: none;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    label {
      margin-bottom: 0;
      padding: 3px 10px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;

      &.open,
      .un-occupied {
        background-color: #e5f8ee;
        color: #0b9444;
      }

      &.closed,
      .occupied {
        background-color: #f6e4e4;
        color: red;
      }
    }
  }

  .list-item {
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    label {
      padding: 5px 10px;
      font-size: 12px;
      border-radius: 15px;
      background-color: #53de21;
      color: #fff;
    }

    &.un-occupied {
      background-color: #e5f8ee;
      color: #0b9444;

      span {
        font-weight: 500;
        height: 40px;
      }

      label {
        background-color: #53de21;
      }
    }

    &.occupied {
      background-color: #f6e4e4;
      color: red;

      span {
        font-weight: 500;
        height: 40px;
      }

      label {
        background-color: red;
      }
    }

    &.open {
      background-color: #e5f8ee;
      color: #0b9444;

      span {
        font-weight: 500;
        height: 40px;
        overflow-wrap: normal;
        word-wrap: normal;
        word-break: keep-all;
      }

      label {
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        background-color: #b8f0d2;
        color: #0b9444;
        text-transform: uppercase;
      }
    }

    &.closed {
      background-color: #f6e4e4;
      color: red;

      span {
        font-weight: 500;
        height: 40px;
        overflow-wrap: normal;
        word-wrap: normal;
        word-break: keep-all;
      }

      label {
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        background-color: #fec6c6;
        color: red;
        text-transform: uppercase;
      }
    }
  }
}

.gray {
  color: #5e5e5e;
}

.notifyCard {
  background-color: #5ab684 !important;
  color: #fff !important;
  padding: 10px;

  p {
    color: #fff;
    font-size: small;
    margin: 10px 0 !important;
  }

  .heading {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    button {
      background-color: #fff !important;
      border-radius: 15px;
      width: 40px;
      height: 20px;
      color: #009444 !important;
      box-shadow: none;
    }
  }

  button {
    background-color: #fff;
    color: #009444;
    box-shadow: none;
  }
}

.timeline-error {
  background-color: #ea5455 !important;
}

.timeline-dot {
  box-shadow: none !important;

  &.error {
    background-color: #ea5455 !important;
    border: 6px solid #fceaeb !important;
  }

  &.warning {
    background-color: #ff9f43 !important;
    border: 6px solid #fff3e8 !important;
  }

  &.success {
    background-color: #009444 !important;
    border: 6px solid #d7f5fc !important;
  }
}

.timeline-warning {
  background-color: #ff9f43 !important;
}

.timeline-success {
  background-color: #009444 !important;
}

.timeline-content {
  display: flex;
  justify-content: space-between;

  span {
    color: #959595;
    margin-bottom: 10px;
    display: block;
  }

  label {
    padding: 2px 15px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
}

@media screen and (max-width: 960px) {
  .timeline-content {
    flex-direction: column;
  }

  .mobileViewFlex {
    flex-direction: column;
  }

  .flexWrap {
    flex-wrap: wrap;
  }
}

.MuiTimelineItem-missingOppositeContent:before {
  content: none !important;
}

.contact {
  background-color: #f0f1f2;
  border-radius: 4px;
  padding: 2em 0;
  text-align: center;

  h4 {
    color: #5e5e5e;
    font-size: 1.3rem;
  }

  h6 {
    color: #959595;
    font-size: 0.9rem !important;
  }

  .MuiIconButton-root {
    background-color: #d3e6dd;
    border-radius: 4px;
    margin-bottom: 15px;
  }
}

.HomeIcon {
  font-size: 1.8em !important;
}
@media only screen and (max-width: 700px) {
    .cardgraphset .MuiCardHeader-content {
        margin-bottom: 15px;
        text-align: center;
    }

    .cardgraphset .MuiCardHeader-root {
        flex-direction: column;
        justify-content: center;
    }

    .go2642161244 .dropdown-heading-value {
        max-width: 280px;
        overflow: auto !important;
    }

    .MuiBox-root-140 {
        flex-direction: column !important;
    }

    .selectboxcss {
        flex-direction: column !important;
    }

    .cardgraphset .MuiCardHeader-action {
        align-self: center !important;
    }

    .makeStyles-page_title-129 {
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 701px) and (max-width: 900px) {
    .cardgraphset .MuiCardHeader-content {
        margin-bottom: 15px;
        text-align: center;
    }

    .MuiContainer-fixed {
        max-width: 700px !important;
    }

    .cardgraphset .MuiCardHeader-root {
        justify-content: center;
        flex-direction: column;
    }

    .cardgraphset .MuiCardHeader-action {
        align-self: center !important;
    }

    .go2642161244 .dropdown-heading-value {
        max-width: 400px;
        overflow: auto;
    }
}

.adminbg {
    background: none !important;
}

.multiselectbox .go3425554998 {
    background: white;
    min-width: 200px;
}

.multiselectbox .go3425554998>div:nth-child(1) {
    padding: 5px 15px;
}
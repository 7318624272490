.Weather {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.Weather__info {
  text-align: left;
  display: flex;
    margin-top: -15px;
}

.Weather__icon {
  width: 35px;
  height: 35px;
  animation-name: sun;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes sun {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.Weather__list {
  font-size: 0.85rem;
  list-style: none;
  margin-left: 0;
  padding:0;
  width: 130px;
  text-align: center;
  color: #fff;
  margin-top: -1.3rem;
}

.list__temperature {
  font-size: 2.5rem;
  height: 50px;
  transition: scale 0.3s, padding-left 0.3s;
}

.list__temperature:hover {
  scale: 1.5;
}

.list__temperature-symbol {
  font-weight: 500;
  font-size: 1rem;
}

.other-info__city {
  font-size: 1.15rem;
  color: #fff;
  margin-top: 0.2rem;
}

.other-info__clouds {
  font-weight: normal;
  font-size: 0.9rem;
  color: #fff;
  width:130px;
}
.today_style{
  color: yellow;
}
.Weather {
  padding: 0 0 0.2rem 0;
}

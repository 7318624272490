.WeatherAndForecast {
  display: flex;
  color: #f2f2f2;
  height: 93px;
}

.WeatherAndForecast__container {
  display: flex;
  justify-content: space-around;
  padding-top: 0.5rem;
  width: 100%;
}

@media (max-width: 375px) {
  .WeatherAndForecast {
    padding: 2rem;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .WeatherAndForecast {
    flex-direction: column;
    height: auto;
  }
}
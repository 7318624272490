@media only screen and (min-device-width: 50px) and (max-device-width: 100px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.2);
    min-width: 200px;
  }
}

@media only screen and (min-device-width: 60px) and (max-device-width: 100px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.2);
    min-width: 230px;
  }
}

@media only screen and (min-device-width: 70px) and (max-device-width: 100px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.3);
    min-width: 200px;
  }
}

@media only screen and (min-device-width: 80px) and (max-device-width: 100px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.4);
    min-width: 170px;
  }
}

@media only screen and (min-device-width: 90px) and (max-device-width: 100px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.5);
    min-width: 160px;
  }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 100px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.6);
    min-width: 150px;
  }
}

@media only screen and (min-device-width: 110px) and (max-device-width: 150px) {
  .checking-csp-status {
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    -webkit-transform: scale(0.8);
    min-width: 150px;
  }
}
